import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import InputMask from 'react-input-mask';
import IconCloseS from '@src/components/Icon/set/IconCloseS';

import css from './style.module.scss';

const Input = (props) => {
	const {
		Icon,
		mods,
		placeholder,
		value,
		type,
		name,
		onChange,
		onInput,
		onInputCleaned,
		onFocus,
		onBlur,
		children,
		onFocusChange,
		Append,
		appearance,
		theme,
		inputProps,
		readonly,
		mask,
		errorText,
		onClick,
		isPlaceholderNoHover,
		clearable,
		ClearIcon,
		loading,
		maskPlaceholder,
		inputMix,
		autofocus,
	} = props;

	const input = useRef();
	const inputContainer = useRef();

	React.useEffect(() => {
		if (autofocus) {
			input.current.focus();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleInput = useCallback(
		({ target: { value: targetValue } }) => {
			if (onInput) onInput.call(null, targetValue);
		},
		[onInput],
	);

	const handleChange = useCallback(
		(e) => {
			const {
				target: { value: targetValue },
			} = e;
			if (onChange) {
				onChange.call(null, targetValue, e);
			}
		},
		[onChange],
	);

	const handleBlur = useCallback(
		(e) => {
			const {
				target: { value: targetValue },
			} = e;
			if (!inputContainer.current.contains(e.relatedTarget)) {
				if (onBlur) {
					if (mask && targetValue?.includes(maskPlaceholder)) {
						onBlur.call(null, false, '');
					} else {
						onBlur.call(null, false, targetValue);
					}
				}
				if (mask && targetValue.includes(maskPlaceholder) && onChange) {
					onChange.call(null, '', e);
				}
			}
		},
		[onBlur, mask, maskPlaceholder, onChange],
	);

	const handleFocusChange = useCallback(
		(e) => {
			if (e.type === 'focus') {
				if (onFocus) onFocus.call(null, e);
				if (onFocusChange) onFocusChange.call(null, true, e);
			} else {
				if (onBlur) onBlur.call(null, e);
				if (onFocusChange) onFocusChange.call(null, false, e);
			}
		},
		[onFocus, onBlur, onFocusChange],
	);

	const cleanInput = useCallback(
		(e) => {
			const resetValue = '';
			input.current.value = resetValue;
			if (mask) {
				setTimeout(() => {
					input.current.focus();
				}, 0);
			} else {
				input.current.focus();
			}
			if (onInputCleaned) onInputCleaned.call(null, e);
			if (onChange)
				onChange.call(null, resetValue, { target: input.current });
			if (onInput) onInput.call(null, resetValue);
			e.stopPropagation();
		},
		[onInputCleaned, onChange, onInput, mask],
	);

	const handleContainerClick = useCallback(
		(e) => {
			input.current.focus();
			if (onClick) onClick.call(null, e, input.current);
		},
		[onClick],
	);
	let isClearAvail = !!value && clearable && !loading;
	if (mask) {
		isClearAvail =
			!!value &&
			value !== mask.replace(/9/g, maskPlaceholder) &&
			clearable &&
			!loading;
	}

	return (
		<div
			className={cn(
				css.block,
				{
					[css.block_error]: !!errorText,
					[css[`block_theme_${theme}`]]: Boolean(theme),
					[css[`block_appearance_${appearance}`]]:
						Boolean(appearance),
					[css.block_valued]: Boolean(value),
				},
				mods,
			)}
			onBlur={handleBlur}
			ref={inputContainer}
			onClick={handleContainerClick}
			tabIndex="-1"
			role="textbox"
		>
			{Icon && (
				<div className={css.icon}>
					<Icon />
				</div>
			)}
			<div className={css.container}>
				{!mask && (
					<input
						id={name}
						type={type}
						placeholder={placeholder}
						value={value}
						onInput={handleInput}
						onChange={handleChange}
						onFocus={handleFocusChange}
						onBlur={handleFocusChange}
						ref={input}
						autoComplete="off"
						tabIndex="0"
						name={name}
						className={cn(css.input, inputMix)}
						readOnly={readonly}
						{...inputProps}
					/>
				)}
				{!!mask && (
					<InputMask
						mask={mask}
						value={value}
						maskPlaceholder={maskPlaceholder}
						onChange={handleChange}
						onBlur={handleBlur}
						onFocus={handleFocusChange}
						onInput={handleInput}
					>
						{() => {
							return (
								<input
									id={name}
									type={type}
									placeholder={placeholder}
									ref={input}
									autoComplete="off"
									tabIndex="0"
									name={name}
									className={cn(css.input, inputMix)}
									readOnly={readonly}
									{...inputProps}
								/>
							);
						}}
					</InputMask>
				)}
				{placeholder && (
					<label
						htmlFor={name}
						className={cn(css.placeholder, {
							[css.placeholder_nohover]: isPlaceholderNoHover,
						})}
					>
						{placeholder}
					</label>
				)}
			</div>
			{Append && !isClearAvail && (
				<div className={css.append}>
					<Append />
				</div>
			)}
			{isClearAvail && (
				<div
					className={css.clear}
					onClick={cleanInput}
					tabIndex={-1}
					role="button"
				>
					<ClearIcon className={css.closeIcon} />
				</div>
			)}
			{children && <div className={css.content}>{children}</div>}
		</div>
	);
};

Input.propTypes = {
	Icon: PropTypes.elementType,
	mods: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.string),
		PropTypes.string,
	]),
	placeholder: PropTypes.string,
	value: PropTypes.string,
	CleanInputButton: PropTypes.elementType,
	type: PropTypes.oneOf([
		'text',
		'search',
		'email',
		'password',
		'tel',
		'url',
		'number',
	]),
	name: PropTypes.string,
	onInput: PropTypes.func,
	onInputCleaned: PropTypes.func,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	onFocusChange: PropTypes.func,
	Append: PropTypes.elementType,
	theme: PropTypes.oneOf(['shadow', 'stroke', 'flat']),
	appearance: PropTypes.oneOf([
		'default',
		'select',
		'select-material',
		'simple',
	]),
	inputProps: PropTypes.objectOf(
		PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.func,
			PropTypes.number,
			PropTypes.bool,
		]),
	),
	readonly: PropTypes.bool,
	mask: PropTypes.oneOfType([PropTypes.instanceOf(RegExp), PropTypes.string]),
	errorText: PropTypes.string,
	onClick: PropTypes.func,
	ClearIcon: PropTypes.elementType,
	isPlaceholderNoHover: PropTypes.bool,
	clearable: PropTypes.bool,
	loading: PropTypes.bool,
	maskPlaceholder: PropTypes.string,
	inputMix: PropTypes.string,
	autofocus: PropTypes.bool,
};

Input.defaultProps = {
	autofocus: undefined,
	Icon: undefined,
	ClearIcon: IconCloseS,
	mods: [],
	placeholder: undefined,
	value: '',
	CleanInputButton: undefined,
	type: 'text',
	name: undefined,
	onInput: undefined,
	onInputCleaned: undefined,
	onChange: undefined,
	onFocus: undefined,
	onBlur: undefined,
	children: undefined,
	onFocusChange: undefined,
	Append: undefined,
	appearance: 'default',
	theme: 'shadow',
	inputProps: undefined,
	readonly: undefined,
	mask: undefined,
	errorText: undefined,
	onClick: undefined,
	isPlaceholderNoHover: true,
	clearable: true,
	loading: false,
	maskPlaceholder: '_',
	inputMix: undefined,
};

export default Input;
