import React from 'react';
import Icon from '../icon';

import closeM from '../svg/close.s.sprite.svg';

const IconCloseS = (props) => {
	return <Icon {...props} sprite={closeM} />;
};

IconCloseS.propTypes = Icon.propTypes;
IconCloseS.defaultProps = Icon.defaultProps;

export default IconCloseS;
